<script>
import { mapState, mapActions } from 'pinia';
import { addSource } from './Utils';
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';
import VectorField from './VectorField';

export default {
  name: 'WindOverlay',

  mixins: [
    AnimatedOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  data() {
    return {
      frames: [],
      isAllAccess: true,
      isForecast: true,
      source: {
        get_forecasts: true,
        has_legend: true,
        has_mask: true,
        has_tile_index: true,
        short_name: OverlayNames.wind,
        tile_count: 50,
        tile_sort: 'asc',
        ranges: this.tileRangesHrrr(0),
        tile_types: ['rasters', 'points'],
      },
      vectorField: null,
      toastOpen: false,
    };
  },

  computed: {
    ...mapState(useUserStore, {
      units: (state) => state.preferences.units,
    }),

    ...mapState(useMapStore, ['is3D']),

    textFieldLabel() {
      const imperialLabel = ['concat', ['get', 'DN'], ''];
      const metricLabel = [
        'concat',
        ['*', ['round', ['/', ['*', 1.609, ['get', 'DN']], 5]], 5],
        '', // ' kmh'
      ];

      return ['imperial', 'uk', 'us'].includes(this.units) ? imperialLabel : metricLabel;
    },

    vectorFieldLayerId() { return 'wind-gust-vector-field'; },
  },

  watch: {
    currentFrame(currentFrame) {
      if (this.isActive && this.vectorField) {
        this.swapDirectionData(currentFrame);
        const layerBelow = this.map.getLayer('hillshade') ? 'hillshade' : 'contour-line';
        this.map.moveLayer(this.vectorFieldLayerId, layerBelow);
      }
    },
    isActive(active, oldActive) {
      // disable 3d on mobile because it seemed
      // to be causing crashes relatively often
      // if (this.$device.isMobileOrTablet) {
      //   this.setCanToggle3D(!active);
      // }
      if (!this.vectorField) {
        return;
      }
      if (!active) {
        const vf = this.vectorField;
        vf.stopAnimation();
      }
      if (active && !oldActive) {
        this.map.easeTo({ pitch: 0 });
        this.swapDirectionData(this.currentFrame);
      }
    },
    overlayOpacity(opacity) {
      if (this.vectorField) {
        const vf = this.vectorField;
        vf.updateOpacity(opacity * 0.4 + 0.6);
      }
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setCanToggle3D']),
    /**
     * Override method from mixin to render a raster layer and use the points tile for the labels
     * layer.
     */
    async add() {
      await this.updateMapSourceAndFrames();
      this.startStaleCheckInterval();

      if (this.frames.length > 0) {
        this.show();
        return;
      }

      await this.addImage();
      this.mapSource.tiles.points.forEach((pointTile, index) => this.addFrame(pointTile, index));
      this.addOverlayMask({}, this);
      if (this.mapSource.tiles && !this.is3D) {
        this.addVectorField();
        setTimeout(() => {
          this.swapDirectionData(this.currentFrame);
        }, 1000);
      }

      this.addEvents();
      this.show();
    },

    swapDirectionData(frameNumber) {
      if (!this.vectorField) {
        return;
      }
      const vf = this.vectorField;
      vf.stopAnimation();
      const frame = this.frames[frameNumber];
      if (!frame) {
        return;
      }
      if (frame.windDirection.data) {
        vf.updateDirectionData(frame.windDirection);
        if (!this.is3D && this.map.getBearing() === 0) {
          vf.startAnimation();
        }
      }
    },

    handleResize() {
      if (!this.vectorField) {
        return;
      }
      // stop animation and clear canvas
      this.vectorField.stopAnimation();
      this.vectorField.resize();
      if (!this.is3D) {
        this.vectorField.startAnimation();
      }
    },

    handleMoveStart() {
      if (!this.vectorField) {
        return;
      }
      // stop animation and clear canvas
      this.vectorField.stopAnimation();
    },

    handleMoveEnd() {
      if (!this.vectorField) {
        return;
      }
      if (this.is3D && !this.toastOpen) {
        this.$toast.open({ message: 'Wind particle effects only available in 2D', type: 'default' });
        this.toastOpen = true;
      }
      if (!this.is3D && this.map.getBearing() !== 0 && !this.toastOpen) {
        this.$toast.open({ message: 'Wind particle effects only available with north pointing up', type: 'default' });
        this.toastOpen = true;
      }
      // start animating again
      if (!this.is3D && this.map.getBearing() === 0 && this.isActive) {
        this.vectorField.startAnimation();
        this.toastOpen = false;
      }
    },

    addEvents() {
      this.map.on('resize', this.handleResize);
      this.map.on('movestart', this.handleMoveStart);
      this.map.on('moveend', this.handleMoveEnd);
    },

    removeEvents() {
      this.map.off('resize', this.handleResize);
      this.map.off('movestart', this.handleMoveStart);
      this.map.off('moveend', this.handleMoveEnd);
      if (this.vectorField) {
        this.map.removeLayer(this.vectorFieldLayerId);
      }
    },

    addVectorField() {
      const layerBelow = this.map.getLayer('hillshade') ? 'hillshade' : 'contour-line';

      this.map.addVectorField = VectorField;

      const layerId = this.vectorFieldLayerId;
      if (this.map.getLayer(layerId)) {
        return;
      }

      this.map.addLayer({
        id: layerId,
        type: 'custom',
        renderingMode: '2d',
        onAdd(map, gl) {
          // this is now the layer
          this.vectorField = map.addVectorField(map, gl);
          this.vectorField.initialize();
        },
        render() {
          this.vectorField.draw();
        },
        onRemove() {
          this.vectorField.cleanup();
        },
      }, layerBelow);
      this.vectorField = this.map.getLayer(layerId).vectorField;
    },

    addImage() {
      const promise = new Promise((resolve) => {
        const id = 'wind-arrow';
        const src = 'https://blizzard.opensnow.com/maps/icons/wind-arrow.svg';
        if (!this.map.hasImage(id)) {
          const img = new Image(42, 54);
          img.crossOrigin = 'Anonymous';
          img.onload = () => {
            this.map.addImage(id, img, { pixelRatio: 2 });
            resolve();
          };
          img.src = src;
        }
        else {
          resolve();
        }
      });
      return promise;
    },

    async loadDirection(src, outputObject) {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.addEventListener('load', () => {
        const canvas = document.createElement('canvas');
        canvas.height = img.height;
        canvas.width = img.width;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        outputObject.data = ctx.getImageData(0, 0, img.width, img.height);
        canvas.remove();
        img = null;
      });
      img.src = src;
    },

    addFrame(pointTile, index) {
      /* eslint camelcase: off */
      // const _labelLayerBelow = this.map.getLayer('settlement-label')
      //   ? 'settlement-label'
      //   : 'settlement-minor-label';
      const { source_timestamp } = pointTile;
      const rasterTile = this.mapSource.tiles.rasters[index];

      const pointSource = addSource({ tile: pointTile, type: 'vector' }, this);
      const rasterSource = addSource({ tile: rasterTile, type: 'raster' }, this);

      if (!pointSource || !rasterSource) {
        return;
      }

      const windDirection = {};
      if (pointTile.vector_field) {
        const { vector_field } = pointTile;
        windDirection.bounds = vector_field.bounds;
        windDirection.dimensions = vector_field.dimensions;
        this.loadDirection(vector_field.file_url, windDirection);
      }

      // const labels = addLabels({
      //   filter: ['all', ['has', 'wind_dir'], ['has', 'wind_gust_speed']],
      //   layerBelow: labelLayerBelow,
      //   minzoom: 0,
      //   layout: {
      //     'icon-image': 'wind-arrow',
      //     'icon-rotate': ['get', 'wind_dir'],
      //     'symbol-placement': 'point',
      //     'text-allow-overlap': false,
      //     'text-ignore-placement': false,
      //     'text-field': ['step', ['zoom'], '', 7, this.textFieldLabel],
      //     'text-offset': ['step', ['zoom'], [0, 0], 7, [0, -1.8]],
      //     'icon-offset': ['step', ['zoom'], [0, 0], 7, [0, 1]],
      //     visibility: 'none',
      //   },
      //   paint: {
      //     'icon-opacity': 0,
      //     'text-opacity': 0,
      //   },
      //   tile: pointTile,
      // }, this);
      const layer = this.addRaster({ source: rasterSource });

      const frame = {
        // labels,
        layer,
        sources: [rasterSource],
        source_timestamp,
        windDirection,
      };

      this.frames.push(frame);
    },

    addRaster({ source }) {
      const layerBelow = this.map.getLayer('hillshade') ? 'hillshade' : 'contour-line';

      this.map.addLayer({
        id: source,
        type: 'raster',
        source,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'raster-opacity': 0,
          'raster-opacity-transition': {
            duration: 0,
            delay: 0,
          },
        },
      }, layerBelow);

      return { id: source, opacities: ['raster-opacity'] };
    },
  },
};
</script>
