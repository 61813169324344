<script>
import { addSource } from './Utils';
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';

export default {
  name: 'PrecipTypeOverlay',

  mixins: [
    AnimatedOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  data() {
    return {
      frames: [],
      isAllAccess: true,
      isForecast: true,
      source: {
        get_forecasts: true,
        has_legend: true,
        has_mask: false,
        has_tile_index: true,
        short_name: OverlayNames.precipType,
        tile_count: 86,
        ranges: [{ start: 0, stop: 360, step: 1 }],
        tile_sort: 'asc',
        tile_types: ['rasters'],
      },
    };
  },

  methods: {
    async add() {
      await this.updateMapSourceAndFrames();
      this.startStaleCheckInterval();

      if (this.frames.length > 0) {
        this.show();
        return;
      }

      this.tiles.forEach((tile) => this.addFrame(tile));

      this.show();
    },
    addFrame(tile) {
      /* eslint camelcase: off */
      const { source_timestamp } = tile;
      const sourceId = addSource({ tile, type: 'raster' }, this);

      if (!sourceId) {
        return;
      }

      const layer = this.addRaster({ sourceId, tile });

      this.frames.push({
        layer,
        source: sourceId,
        source_timestamp,
      });
    },
    addRaster({ sourceId, tile }) {
      const layerBelow = this.map.getLayer('hillshade') ? 'hillshade' : 'contour-line';

      const rasterConfig = tile.raster_render_config;
      const rasterColorization = {};
      if (rasterConfig) {
        if (rasterConfig.raster_color_type) {
          rasterColorization['raster-color'] = this.getRasterColor(rasterConfig.raster_color_type);
        }
        if (rasterConfig.raster_color_range) {
          rasterColorization['raster-color-range'] = rasterConfig.raster_color_range;
        }
        if (rasterConfig.raster_resampling) {
          rasterColorization['raster-resampling'] = rasterConfig.raster_resampling;
        }
        if (rasterConfig.raster_color_mix) {
          rasterColorization['raster-color-mix'] = rasterConfig.raster_color_mix;
        }
      }

      this.map.addLayer({
        id: sourceId,
        type: 'raster',
        source: sourceId,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'raster-opacity': 0,
          'raster-opacity-transition': {
            duration: 0,
            delay: 0,
          },
          ...rasterColorization,
        },
      }, layerBelow);

      return { id: sourceId, opacities: ['raster-opacity'] };
    },
  },
};
</script>
